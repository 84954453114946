<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('common.gambar') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="photo.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.gambar') }}:</label>
              <div class="col-lg-6">
                <div
                  class="dropzone"
                  action="inc/api/dropzone/upload.php"
                  id="kt-dropzone-one"
                >
                  <div class="dropzone-msg dz-message needsclick">
                    <h1><i
                        class="fa fa-upload"
                        aria-hidden="true"
                      ></i></h1>
                    <h3 class="dropzone-msg-title">Upload</h3>
                    <span class="dropzone-msg-desc">Tarik gambar ke sini atau klik untuk upload.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.judul') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="photo.title"
                  v-validate="'required'"
                  data-vv-as="Judul"
                  name="title"
                  :class="{'is-invalid': errors.has('title') }"
                />
                <div
                  v-show="errors.first('title')"
                  class="invalid-feedback"
                >{{ errors.first('title') }}</div>
              </div>
            </div>
             <div class="form-group row">
              <label class="col-lg-4 col-form-label">Alias:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="photo.alias"
                  v-validate="'required'"
                  data-vv-as="Judul"
                  name="title"
                  :class="{'is-invalid': errors.has('title') }"
                />
                <div
                  v-show="errors.first('title')"
                  class="invalid-feedback"
                >{{ errors.first('title') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.keterangan') }}:</label>
              <div class="col-lg-6">
                <textarea
                  type="text"
                  class="form-control"
                  v-model="photo.description"
                >
                </textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../../_general/DatePicker";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import Photo from "../../../model/photo-model";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PhotoRepository = RepositoryFactory.get("photo");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      photo: new Photo()
    };
  },
  props: {
    modalName: { type: String },
    modalData: {}
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    updateData: function (data) {
      this.photo = data;
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {

          if (this.photo.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data Gambar akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.photo);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data Gambar akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(vx.photo);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
          return;
        }
      });
    },
    async update(payload) {
      var vx = this;

      await PhotoRepository.updatePhoto(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Gambar berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data Gambar gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;

      await PhotoRepository.createPhoto(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Gambar berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Gambar gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    var vx = this;

    $("#" + this.modalName).modal();

    var myDropzone = new Dropzone("#kt-dropzone-one", {
      paramName: "file", // The name that will be used to transfer the file
      maxFiles: 1,
      maxFilesize: 5, // MB
      addRemoveLinks: true,
      autoProcessQueue: false,
      createImageThumbnails: true,
      accept: function (file, done) {
        if (file.name == "justinbieber.jpg") {
          done("Naha, you don't.");
        } else {
          done();
        }
      },
      init: function () {
        this.on("thumbnail", function (file) {
          var replacedDataURL = file.dataURL.substring(file.dataURL.indexOf(",") + 1);
          vx.photo.image = replacedDataURL;
          vx.photo.imageContentType = file.type;
          vx.photo.height = file.height;
          vx.photo.width = file.width;
          vx.photo.taken = moment(file.lastModified);
          vx.photo.uploaded = moment();
        });
        this.on("removedfile", function (file) {
          vx.photo.image = null;
          vx.photo.imageContentType = null;
          vx.photo.height = null;
          vx.photo.width = null;
          vx.photo.taken = null;
          vx.photo.uploaded = null;
        });
      }
    });

    if (this.modalData) {
      this.updateData(this.modalData);

      if (this.modalData.image) {
        var mockFile = { name: "mock.jpg", size: 12345 };
        myDropzone.options.addedfile.call(myDropzone, mockFile);
        myDropzone.options.thumbnail.call(myDropzone, mockFile, "data:" + this.modalData.imageContentType + ";base64," + this.modalData.image);
      }
    }
  }
};
</script>
